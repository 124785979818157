import './leibniz.scss';

// import React from 'react'; orig version
import React, { useContext, useState, useEffect, useRef } from 'react'; // 12.10.21
import * as componentUtility from "../../search/componentUtility"; // 12.10.21
import SearchContext from "../context/SearchContext"; // 12.10.21
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
/* eslint no-console: 0  no-dupe-keys: 0 */

export type ILeibnizProp = StateProps;

export const Leibniz = (props: ILeibnizProp) => {
	const { account } = props;
{/*	function submitForm(e) {
		this.props.history.push('/search');

	} */}
	
	// 12.10.21:
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef(); 
	let activeLanguageSwitch = false;
	
	// 12.10.21:
	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); activeLanguageSwitch = true; localStorage.setItem('lang', e.currentTarget.value) }}  >{ds}</select>;
	}
		
	const searchLangSelect = searchLangSelector();	
	
	return (
		<Container>
			<Row>
				<Col md="9">
					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a>
				</Col>
				<Col md="3" className="searchmod">

					{searchLangSelect}
				</Col>
			</Row>		
			<Row>

				<Col md="3">
					<a href="content/images/csm_karte_a4_lg_insitute_2014_de_sektionen_351902245e.jpg">
						<img src="content/images/csm_karte_a4_lg_insitute_2014_de_sektionen_351902245e.jpg" width="250px" height="428" alt="Logo" />
					</a>
				</Col>	
				<Col md="9">
{/*					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a> */}
					
					{localStorage.getItem('lang') === "de" ?					
						<h2>Informationen für Leibniz-Einrichtungen</h2>
						: <h2>Information for Leibniz institutions</h2>}
						
					{localStorage.getItem('lang') === "de" ?
						<p className="lo-leibniz"><span>						
							<h3><b>Wie können sich Leibniz-Einrichtungen an LeibnizOpen beteiligen?</b></h3>
							<p> Für die Beteiligung von Leibniz-Einrichtungen gibt es zwei Möglichkeiten: </p>
							<ol><li>Eine Einrichtung kann ihre Inhalte in ein Repository einer koordinierenden Institution einpflegen und von dort an LeibnizOpen übermitteln lassen. Diese Form der Beteiligung wird durch bilaterale Kooperationsvereinbarungen zwischen den beteiligten Instituten geregelt (<a href="content/documents/memorandum_of_understanding_leibniz.pdf" title="Leitet Herunterladen der Datei ein" className="download">Beispiel für ein Memorandum of Understanding</a>). Bei Interesse wenden Sie sich bitte an <a href="../contact" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Ihre zuständige Ansprechpartnerin oder Ihren zuständigen Ansprechpartner</a>. </li>
							<li>Eine Einrichtung kann sich direkt mit dem eigenen Repository an LeibnizOpen anbinden. Die Bedingungen für eine direkte Anbindung sind definiert in den <a href="../technical" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Technischen Umsetzungshinweisen für Leibniz-Einrichtungen</a>.&nbsp; </li></ol>
							<h3><b>Nutzungsvereinbarung</b></h3>
							<p> Die Nutzungsvereinbarung gewährleistet das Einverständnis der Autorin oder des Autors mit der Speicherung und öffentlichen Verfügbarmachung seines Werks in LeibnizOpen bzw. den LeibnizOpen zugrundeliegenden Repositories. </p>
							<ul><li><a href="content/documents/nutzungsvereinbarung_leibniz.pdf" title="Leitet Herunterladen der Datei ein" className="download">Vertragsvorlage Nutzungsvereinbarung</a> </li>
							<li><a href="content/documents/checkliste_nutzungsvereinbarung_leibniz.pdf" title="Leitet Herunterladen der Datei ein" className="download">Checkliste mit Erläuterungen zur Nutzungsvereinbarung</a> </li></ul>
							<p>Die Vertragsvorlage wurde von der Geschäftsstelle der Leibniz-Gemeinschaft in Zusammenarbeit mit der AG LeibnizOpen entwickelt. Sie dient den Betreibern der LeibnizOpen zugrundeliegenden Repositories als gemeinsame Grundlage, die für den Einsatz in der Praxis auf die jeweiligen Workflows und technischen Systeme angepasst wurde. Die Vertragsvorlage und die Checkliste stehen unter einer <a href="https://creativecommons.org/licenses/by/3.0/deed.de" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Creative Commons Namensnennung 3.0 Unported Lizenz</a>.</p>
							<h3><b>Wie können sich Autorinnen und Autoren die Rechte für eine Zweitveröffentlichung ihrer Publikation auf einem Repositorium sichern?</b></h3>
							<p> Maßgeblich für die Möglichkeiten, die Autorinnen und Autoren bei der Zweitveröffentlichung im Open Access haben, ist der Verlagsvertrag (Autorenvertrag). Die meisten Verlage sehen in ihren Verträgen die Möglichkeit einer parallelen Bereitstellung von Werken in institutionellen oder fachlichen Repositorien vor. Oft darf dabei die letzte Autorenversion nach dem Peer-Reviewing (akzeptiertes Manuskript), selten die vom Verlag publizierte Endfassung verwendet werden. Häufig ist eine Bereitstellung erst nach einer Embargofrist von mehreren Monaten möglich. Eine Übersicht über die Policies vieler Zeitschriftenverlage liefert <a href="http://www.sherpa.ac.uk/romeo/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">SHERPA/RoMEO</a>.</p>
							<p>Falls die Möglichkeit einer Zweitveröffentlichung in den Verträgen nicht vorgesehen ist, wird Autorinnen und Autoren empfohlen, dem Verlagsvertrag einen Zusatz beizufügen, um sich ein einfaches Nutzungsrecht für die Zweitveröffentlichung auf einem Repositorium vorzubehalten.</p>
							<p>Zwar räumt die Neuregelung in § 38 Abs. 4 UrhG Autoren unter bestimmten Einschränkungen bereits ein gesetzliches Zweitveröffentlichungsrecht beispielsweise in einem Repositorium ein, <br />jedoch gilt dies nur für Zeitschriftenbeiträge sowie erst nach Ablauf von 12 Monaten seit der Erstveröffentlichung bei einem Verlag.</p>
							<p>Für die an LeibnizOpen angebundenen Repositorien wurden in Zusammenarbeit mit der Geschäftsstelle Formulierungshilfen für Vertragszusätze entwickelt. Unter Umständen können damit günstigere Bedingungen für die Veröffentlichung auf Repositorien erreicht werden, als sie das Zweitveröffentlichungsrecht eröffnet. Zudem können diese auch für Publikationsarten verwendet werden, für die das Zweitveröffentlichungsrecht nicht gilt (z.B. Aufsätze in Sammelwerken, Beiträge in Konferenzbänden, Monographien). Für diese Vereinbarung mit dem Verlag sind verschiedene Varianten denkbar, die in der Vorlage auswählbar sind. Bitte wählen Sie die Optionen aus, die Ihren Anforderungen entsprechen.</p>
							<p>Der nachfolgende Formulierungsvorschlag sichert den Autorinnen und Autoren nach einer individuell festzulegenden Embargofrist ein Nutzungsrecht an der Manuskriptversion bzw. der Verlagsversion für das öffentliche Zugänglichmachen im Internet zu:</p>
							<ul><li><a href="content/documents/vertragszusatz_verlagsvertrag_formulierungshilfe_leibniz.pdf" title="Leitet Herunterladen der Datei ein" className="download">Vertragszusatz zum Verlagsvertrag für Autoren </a></li></ul>
							<p>Diese Vereinbarung sollte dem Verlag vor Abschluss des Verlagsvertrages vorgelegt und spätestens mit dem Abschluss des Verlagsvertrags unterschrieben werden, um zu verhindern, dass eine entgegenstehende Regelung getroffen wird.</p>		
						</span></p>
						:<p className="lo-leibniz"><span>						
							<h3><b>How can Leibniz institutions participate in LeibnizOpen?</b></h3>
							<p>In order to deposit documents in the LeibnizOpen repository, Leibniz institutions have the following options: </p>
							<ol><li>An institution can feed its content manually into the repository of a coordinating institution. From there it will be harvested into LeibnizOpen. This form of participation will be regulated by bilateral co-operation agreements between the institutions concerned (<a href="content/documents/memorandum_of_understanding_leibniz.pdf" title="Opens internal link in current window">example &quot;Memorandum of Understanding&quot;, in German</a>). Should you be interested in using this option, please get in touch with a <a href="../contact" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">contact person responsible for your institution</a>.</li>
							<li>An institution can transfer the content from its own repository directly to the LeibnizOpen repository. The terms of a direct transfer are defined in the <a href="../technical" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Technical information for Leibniz institutions</a>.</li></ol>
							<h3><b>Terms of Use Agreement</b> </h3>
							<p> The Terms of Use Agreement ensures consent of the author to storage and making available to the public of his or her work in LeibnizOpen or in the repositories that are harvested for LeibnizOpen.&nbsp; </p>
							<ul><li><a href="content/documents/terms_of_use_agreement_eng.pdf" title="Initiates file download" className="download">Agreement Template &quot;Terms of Use Agreement&quot;<br /></a></li>
							<li><a href="content/documents/checklist_tou_agreement_eng.pdf" title="Initiates file download" className="download">Checklist with explanations concerning the Terms of Use Agreement</a></li></ul>
							<p>The agreement template was developed by the the Leibniz Headquarters in cooperation with the Open Access Working Group of Leibniz Association. It serves the administrators of the repositories LeibnizOpen is built upon as a common basis which has been adjusted to the respective workflows and technical systems for the practical application.&nbsp; </p>
							<p>The agreement template and the checklist are licensed under <a href="http://creativecommons.org/licenses/by/3.0/" title="Opens external link in new window" target="_blank" rel="noopener noreferrer">Creative Commons Attribution 3.0 Unported Licence</a>.</p>
							<h3><span lang="EN-US">How can authors secure their rights to secondary publications for repositories?</span></h3>
							<p><span lang="EN-US">The publishing contract (or author’s contract) is crucial in determining the opportunities for secondary publications in Open Access. Most publishing contracts allow for the possibility of producing a parallel edition of the work intended for institutional or academic repositories. Often it is the final peer-reviewed author’s version, i.e. the accepted manuscript, and not the publisher’s final version that is used for this secondary edition. In many cases the second edition can only be printed after an embargo period of several months. An overview of the policies of many journal publishers can be found at <a href="http://www.sherpa.ac.uk/romeo/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">SHERPA/RoMEO</a>. </span></p>
							<p><span lang="EN-US">In the eventuality that the contract does not stipulate the possibility of a second edition, authors are advised to append an addendum to the publishing agreement reserving the simple right of use for secondary publications intended for repositories.</span></p>
							<p>The new regulation detailed in Section 38, sub sec. 4 GCA (German Copyright Act) grants authors the legal right to secondary publications, e.g. for repositories subject to certain restrictions. However, this regulation only extends to cover journal contributions made more than 12 months after the first publication.</p>
							<p><span lang="EN-US">In cooperation with Leibniz headquarters, wording templates detailing additional agreements for publishing contracts, or ‘addenda’, have been developed for the repositories linked to LeibnizOpen. These will potentially secure better conditions for repository publications than those granted by the legal second publication right.&nbsp; </span>The above conditions can also be applied to publications for which the legal second publication right is not valid, e.g. essays in collected editions, contributions to conference volumes, or monographs. Several variants are possible for this agreement with the publisher and these are selectable within the template. Please select the options that correspond to your needs.</p>
							<p><span lang="EN-US">The following template guarantees the author’s right to make the manuscript and/or publisher’s version available online following an individually determined embargo period:</span></p>
							<ul><li><a href="content/documents/addendum_template_publishing_contract_leibniz.pdf" title="Leitet Herunterladen der Datei ein" className="download">Addendum template to Publishing contract for authors</a></li></ul>
							<p><span lang="EN-US">This agreement should be presented to the publisher prior to the conclusion of the publishing contract and should be signed at the latest at the conclusion of the contract to avoid publishers reaching conflicting decisions.</span></p>						
						</span></p>
					}	
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Leibniz);
