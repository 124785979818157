/* https://www.math.uni-hamburg.de/it/dienste/encryptma.html */

export function UnCryptMailto(s) {
	let n=0;
	let r="";
	for(let i=0; i < s.length; i++) {
		n=s.charCodeAt(i);
		if (n>=8364) {n = 128;}
		r += String.fromCharCode(n-(1));
	}
	return r;
}

export function linkToUnCryptMailto(s) {
	const a = document.createElement('a');
    a.href = UnCryptMailto(s);
    document.body.appendChild(a); // Add to the DOM
    a.click();
    document.body.removeChild(a); // Remove it back
}