import './technical.scss';

// import React from 'react'; orig version
import React, { useContext, useState, useEffect, useRef } from 'react'; // 12.10.21
import * as componentUtility from "../../search/componentUtility"; // 12.10.21
import SearchContext from "../context/SearchContext"; // 12.10.21
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
/* eslint no-console: 0  no-dupe-keys: 0 */
import { useHistory } from "react-router-dom";

export type ITechnicalProp = StateProps;

export const Technical = (props: ITechnicalProp) => {
	const history = useHistory();
	const { account } = props;
{/*	function submitForm(e) {
		history.push('/search');

	}*/}
	
	// 12.10.21:
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef(); 
	let activeLanguageSwitch = false;
	
	// 12.10.21:
	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); activeLanguageSwitch = true; localStorage.setItem('lang', e.currentTarget.value) }}  >{ds}</select>;
	}
		
	const searchLangSelect = searchLangSelector();
		
	return (
		<Container>
			<Row>
				<Col md="9">
					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a>
				</Col>
				<Col md="3" className="searchmod">

					{searchLangSelect}
				</Col>
			</Row>		
			<Row>
				<Col md="12">
{/*					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a> */}
			
					{localStorage.getItem('lang') === "de" ? 					
						<div id="c292" className="lo-technical">
							<div>
							<h2>Technische Umsetzungshinweise für Leibniz-Einrichtungen</h2></div>
							<p>Die koordinierenden Einrichtungen von LeibnizOpen haben sich auf verschiedene Standards verständigt, um das Harvesting der Metadaten aus den einzelnen Fachrepositorien und deren spätere Präsentation in LeibnizOpen möglichst einheitlich zu gestalten. In der folgenden Übersicht finden sich Anforderungen sowie Umsetzungshinweise, die einzuhalten sind, wenn sich Leibniz-Einrichtungen mit ihren eigenen Repositorien oder über Repositorien, die außerhalb der Leibniz-Gemeinschaft gehostet werden, an LeibnizOpen anbinden möchten. 
							</p>
							<h3><b>Metadatenschema</b></h3>
							<p>Damit die Metadaten der verschiedenen Fachrepositorien später gemeinsam weiterverarbeitet werden können, wurde sich auf ein gemeinsames Metadatenschema geeinigt. Dieses Schema basiert auf dem <a href="http://dublincore.org/documents/dces/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Dublin Core Metatdata Element Set</a>, erweitert dessen 15 Elemente jedoch um drei zusätzliche Elemente: „wglsubject“, „wgltype“ und „wglcontributor“. Diese drei Felder sind Pflichtfelder und sollen folgende Einträge enthalten:</p>
							<ul>
							<li>„wglcontributor“: Dieses Feld soll die Abkürzung der Leibniz-Einrichtung des Autors enthalten. Die Abkürzungen sind identisch mit den <a href="https://www.leibniz-gemeinschaft.de/institute/leibniz-institute-nach-kuerzeln" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Abkürzungen, die innerhalb der Leibniz-Gemeinschaft verwendet werden</a>. Bei mehreren Autoren unterschiedlicher Einrichtungen sind dementsprechend mehrere Einträge vorzunehmen.</li>
							<li>„wgltype“: Dieses Feld soll den Dokumenttyp enthalten, wie er in der Liste der <a href="./technical#c302" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Leibniz-Dokumenttypen</a> definiert wird.</li>
							<li>„wglsubject“: Dieses Feld soll einen (oder mehrere) Einträge enthalten, wie sie in der <a href="./technical#c301" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Leibniz-Fächerklassifikation</a> definiert sind. Bei interdisziplinären Veröffentlichungen sind mehrere Einträge vorzunehmen.</li>
							</ul>
							<p> Für die letzten beiden Felder „wgltype“ und „wglsubject“ muss in den Fachrepositorien ggf. ein internes Mapping erstellt und durchgeführt werden, welches die dort verwendeten Dokumenttypen bzw. Fächer (die nach wie vor in den DC-Feldern „type“ und „subject“ stehen können) auf die entsprechenden Einträge der Leibniz-Dokumenttypenliste bzw. -Fächerklassifikation abbilden. 
							</p>
							<p>Den Änderungen und Erweiterungen des Dublin Core Schemas zufolge wird der Namespace von „dc“ in „wgl“ geändert. Dies bedeutet für die Betreiber der beteiligten Repositorien, dass sie lediglich einen Output gemäß dem neuen Schema generieren müssen, dabei aber ihre eigenen standardisierten Ausgabeformate (z.B. mit dem Metadatenpräfix „oai_dc“) nicht verändern müssen. Das oben beschriebene Metadatenschema findet sich unter <a href="content/documents/oai_wgl/oai_wgl.xsd" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">content/documents/oai_wgl/oai_wgl.xsd</a> bzw. <a href="content/documents/wgl_dc/wgl_dc.xsd" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">content/documents/wgl_dc/wgl_dc.xsd</a>. Letzteres definiert die nun insgesamt 18 Metadatenfelder des gemeinsamen LeibnizOpen-Metadatenschemas. 
							</p>
							<h3><b>OAI-Schnittstelle</b></h3>
							<p> Die Metadaten der beteiligten Repositorien können über deren bestehende OAI-Schnittstelle abgerufen werden. Dabei sollen nur die Metadaten von Dokumenten für LeibnizOpen unter dem Präfix „oai_wgl“ zur Verfügung gestellt werden, die den Rahmenbedingungen entsprechen. Die Metadaten sollen gemäß dem oben definiertem Schema geliefert werden. Dabei ist beispielsweise auch eine Sortierung in Sets möglich. 
							</p>
							<h3><b>Sichtbarkeit und Zugänglichkeit der Metadaten</b></h3>
							<p> Durch Harvesting und anschließender Darstellung der Metadaten in LeibnizOpen werden die Metadaten an dieser Stelle zusätzlich im Web sichtbar und somit auch zugänglich für Web-Suchmaschinen. Durch die Bereitstellung einer eigenen OAI-Schnittstelle können die Metadaten darüber hinaus geharvestet werden und stehen somit externen Anbietern und Diensten, aber auch Einrichtungen der Leibniz-Gemeinschaft selbst zur Verfügung, um z.B. Metadaten ihrer Publikationen in ihre Website einzubinden. Die OAI-Schnittstelle ist zu erreichen unter <a href="../../oaip/oai?verb=Identify" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">/oaip/oai?verb=Identify</a>. 
							</p>
							<h3><b>Dateiformat der Volltexte</b></h3>
							<p> Die Volltexte müssen im PDF-Format vorliegen. 
							</p>
							<h3><b>Ansprechpartner</b></h3>
							<p><a href="javascript:linkTo_UnCryptMailto('nbjmup;dmbvt.qfufs/lmbtAhftjt/psh');" title="Öffnet ein Fenster zum Versenden der E-Mail" className="mail">claus-peter [dot] klas [at] gesis [dot] org</a>
							</p>
							<div id="c301" className="lo-technical">
							<div>
							<h2>Leibniz-Fächerklassifikation</h2>
							</div>
							<ul>
							<li>Biowissenschaften/Biologie </li>
							<li>Chemie </li>
							<li>Ernährungswissenschaft </li>
							<li>Erziehung, Schul- und Bildungswesen </li>
							<li>Geowissenschaften</li>
							<li>Geschichte </li>
							<li>Informatik</li>
							<li>Ingenieurwissenschaften </li>
							<li>Kunst, Kultur </li>
							<li>Landwirtschaft </li>
							<li>Literaturwissenschaften </li>
							<li>Mathematik</li>
							<li>Medizin, Gesundheit </li>
							<li>Meereswissenschaft </li>
							<li>Physik </li>
							<li>Psychologie </li>
							<li>Raumwissenschaften </li>
							<li>Religion </li>
							<li>Sozialwissenschaften </li>
							<li>Sprache, Linguistik </li>
							<li>Umweltwissenschaften </li>
							<li>Wirtschaftswissenschaften</li>
							</ul>
							</div>
							<div id="c302" className="lo-technical">
							<div>
							<h2>Leibniz-Dokumenttypen</h2>
							</div>
							<ul>
							<li>Buch / Sammelwerk</li>
							<li>Buchkapitel / Sammelwerksbeitrag </li>
							<li>Konferenzband</li>
							<li>Konferenzbeitrag </li>
							<li>Zeitschrift </li>
							<li>Zeitschriftenartikel</li>
							<li>Hochschulschrift</li>
							<li>Report / Forschungsbericht / Arbeitspapier</li>
							<li> Patent </li>
							<li>Sonstige</li>
							</ul>
							</div>
						</div>	
						:
						<div id="c292" className="lo-technical">
							<div>
							<h2>Technical information for Leibniz institutions</h2></div>
							<p>The institutions operating LeibnizOpen have come together to agree on different standards to coordinate and unify as much as possible the way in which metadata is harvested from the individual disciplinary repositories and then presented in LeibnizOpen. The following overview details instructions and implementation strategies which institutions should follow if they want to integrate their repositories into LeibnizOpen or connect via repositories which are not hosted by Leibniz institutes.
							</p>
							<h3>Metadata Schema</h3>
							<p>A common schema for metadata has been agreed upon so that the different metadata from numerous disciplinary repositories can all be processed together in future. This schema is based on the <a href="http://dublincore.org/documents/dces/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Dublin Core Metatdata Element Set</a> but adds 3 elements to the 15 elements in the set: “wglsubject”, “wgltype“ and “wglcontributor”. These three fields are mandatory fields and should contain the following entries:</p>
							<ul>
							<li>“wglcontributor”: This field should contain the abbreviation of the Leibniz institution to which the author belongs. The abbreviations are identical to the <a href="https://www.leibniz-gemeinschaft.de/en/institutes/leibniz-institutes-abbreviation" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">abbreviations used within the Leibniz Association</a>. In the case of a joint publication by authors from different institutions, an entry should be made for each institution.</li>
							<li>“wgltype”: This field should contain the document type as defined in the <a href="./technical#c302" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">vocabulary of document types for LeibnizOpen</a>.</li>
							<li>“wglsubject”: This field should contain one or more entries as defined in the <a href="./technical#c301" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">LeibnizOpen subject classification</a>. An entry should be made for each discipline in interdisciplinary publications.</li>
							</ul>
							<p>For the final two fields “wgltype” and “wglsubject”, subject-specific repositories might have to produce and implement an internal mapping which the document types and disciplines (which can remain in the DC fields “type” and “subject”) can reproduce on the corresponding entries of the Leibniz document lists and subject classification.</p>
							<p>As a result of the changes and developments of the Dublin Core Schema, the namespace has been changed from “dc” to “wgl”. This means that the operators of the repositories involved only have to generate one output according to the new schema and do not have to change their own standardised output formats (e.g. the prefix “oai_dc”). The metadata scheme described above is available at: <a href="content/documents/oai_wgl/oai_wgl.xsd">/content/documents/oai_wgl/oai_wgl.xsd</a> and <a href="content/documents/wgl_dc/wgl_dc.xsd">/content/documents/wgl_dc/wgl_dc.xsd</a>.</p>
							<p>The second website defines the current 18 metadata fields of the common LeibnizOpen metadata schema.</p>
							<h3>OAI interface</h3>
							<p>The metadata held by the repositories involved can be accessed via their respective OAI interface. Only the metadata for documents which fulfil the framework conditions should be made accessible to LeibnizOpen under the prefix “oai_wgl”. The metadata should be transmitted according to the schema defined above. It is also possible to sort the data into sets.</p>
							<h3>Metadata visibility and accessibility</h3>
							<p>Once the metadata has been harvested and presented in LeibnizOpen, it becomes visible on the web and accessible to web search engines. Thanks to the provision of its own OAI interface, metadata can be harvested so that it is available to external providers and services as well as the institutions of the Leibniz Association themselves. These can, for example, integrate their own metadata into their webpages. The data can be harvested at <a href="../../../oaip/oai?verb=Identify" title="be" target="metadata">/oaip/oai?verb=Identify</a>.</p>
							<h3>File format of full-text versions</h3>
							<p>Full-text versions must be submitted as PDFs.</p>
							<h3>Contact Person</h3>
							<p><a href="javascript:linkTo_UnCryptMailto('jxfiql7Jxexk+WlidexaoXdbpfp+lod');" title="Öffnet ein Fenster zum Versenden der E-Mail" className="mail">Mahan Zolghadr, GESIS</a></p>
							<div id="c301" className="lo-technical">
							<div>
							<h2>Subject classification for LeibnizOpen</h2>
							</div>
							<ul>
							<li>Agriculture</li>
							<li>Art, Culture</li>
							<li>Chemistry</li>
							<li>Computer Science</li>
							<li>#Economics#</li>
							<li>Education</li>
							<li>Engineering Sciences</li>
							<li>Environmental Sciences</li>
							<li>Geo Sciences</li>
							<li>History</li>
							<li>Language, Linguistics</li>
							<li>Life Sciences, Biology</li>
							<li>Literature</li>
							<li>Marine Science</li>
							<li>Mathematics</li>
							<li>Medicine, Health</li>
							<li>Nutrition Science</li>
							<li>Physics</li>
							<li>Psychology</li>
							<li>Religious Studies</li>
							<li>Spatial Sciences</li>
							<li>Social Sciences</li>
							</ul>
							</div>
							<div id="c302" className="lo-technical">
							<div>
							<h2>Vocabulary of document types for LeibnizOpen</h2>
							</div>
							<ul>
							<li>Book / Collective volume</li>
							<li>Book chapter / Contribution to a collective volume</li>
							<li>Contribution to proceedings</li>
							<li>Journal</li>
							<li>Journal article</li>
							<li>Other</li>
							<li>Patent</li>
							<li>Proceedings</li>
							<li>Report / Research paper / Working paper</li>
							<li>Thesis </li>
							</ul>
							</div>
						</div>	
					}
					
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Technical);
